import './App.css';
import React from 'react';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: 'us-east-1:7bd6f0a5-1474-4d6e-812b-301af42e0bbe',
      allowGuestAccess: true
    }
  }
});

function App() {
  console.log("VERSION 1.1.0");
  const [loading, setLoading] = React.useState(true);
  const [livenessSession, setLivenessSession] = React.useState(null);
  
  const validateClientAccessToken = async () => {
      const query = new URLSearchParams(window.location.search);
      const awsSessionId = query.get('awsSessionId');
      if (awsSessionId) {
        setLivenessSession({ sessionId: awsSessionId });
        setLoading(false);
      }
  };

  const getBaseUrl = () => {
    return window.location.protocol + "//" + window.location.host;
  }
  
  React.useEffect(() => {
    validateClientAccessToken();
  }, []);

  const onAnalysisComplete = async () => {
    window.location.replace(getBaseUrl() + "?success=true");
  };

  const askCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    } catch (e) {
      console.error("Error enabling camera:", e);
    }
  };

  const onError = async (error) => {
    if (error == "CAMERA_ACCESS_ERROR") {
        await askCameraPermission();
        setLoading(true);
        setLivenessSession(null);
        validateClientAccessToken();
        return
    }
    window.location.replace(getBaseUrl() + "?failed=" + `${error}`);
  }

  return (
    <ThemeProvider>
      {loading ? (
        <Loader />
      ) : (
         (livenessSession != null) ? (
            <FaceLivenessDetector
              sessionId={livenessSession.sessionId}
              region="us-east-1"
              onAnalysisComplete={onAnalysisComplete}
              onError={(error) => { onError(error.state) }}
            />
         ) : (<></>)
      )}
    </ThemeProvider>
  );
}

export default App;
